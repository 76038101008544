<template>
  <div :class="'user__card bg-' + checkCardColor()">
    <div>
      <span class="user__card__name fw-bold">{{ user.fullName }}</span> (#{{
        user.id
      }})
    </div>
    <div class="user__card__item">
      {{ $t("admin.user.mail_address") }}
      <span class="ms-0 ms-md-1 fw-bold"> {{ user.mail }}</span>
    </div>
    <div class="user__card__item">
      {{ $t("admin.user.already_connected") }}
      <span
        v-if="user.alreadyConnectedOne"
        :class="
          'ms-0 ms-md-1 fw-bold ' +
          (!isDeletedInputCheck && !isKeepedInputCheck ? 'text-success' : '')
        "
      >
        {{ $t("admin.user.yes") }}
      </span>
      <span
        v-else
        :class="
          'ms-0 ms-md-1 fw-bold ' +
          (!isDeletedInputCheck && !isKeepedInputCheck ? 'text-danger' : '')
        "
      >
        {{ $t("admin.user.no") }}
      </span>
    </div>
    <div class="user__card__item">
      {{ $t("admin.user.account_creation") }}
      <span class="ms-0 ms-md-1 fw-bold">{{ formatDate(user.createdAt) }}</span>
    </div>
    <div class="user__card__item">
      {{ $t("admin.user.external_id") }}
      <span class="ms-0 ms-md-1 fw-bold">
        {{ user.externalId }}
      </span>
    </div>
    <div class="user__card__item">
      {{ $t("admin.user.birth_date") }}
      <span class="ms-0 ms-md-1 fw-bold">{{ formatDate(user.birthDate) }}</span>
    </div>
    <fieldset>
      <!-- Ajouter bouton radio pour suppression et sauvegarde -->
    </fieldset>
  </div>
</template>

<script>
import { dateFormatter } from "@/services/utilities/date-formatter";

export default {
  name: "TriadDuplicatedUserDetailComponent",
  components: {},
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isDeletedInputCheck: false,
      isKeepedInputCheck: false,
    };
  },
  methods: {
    formatDate(date) {
      return dateFormatter.format(date, "short");
    },

    checkCardColor() {
      if (this.isDeletedInputCheck) {
        return "danger";
      }
      if (this.isKeepedInputCheck) {
        return "success";
      }
    },

    toogleKeepUser() {
      this.isKeepedInputCheck = !this.isKeepedInputCheck;
      if (this.isDeletedInputCheck) {
        this.isDeletedInputCheck = false;
      }
    },

    toogleDeleteUser() {
      this.isDeletedInputCheck = !this.isDeletedInputCheck;
      if (this.isKeepedInputCheck) {
        this.isKeepedInputCheck = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.user__card {
  font-weight: 200;
  color: var(--primary-color);
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #e0e6ed;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);

  &:not(:last-child) {
    margin-bottom: 8px;
    @include up-to-md {
      margin-bottom: 10px;
    }
  }

  &.-success {
    background-color: #6ac7a4;
  }

  &__name {
    font-weight: 700;
  }

  &__item {
    display: flex;
    @include up-to-xl {
      flex-direction: column;
    }
  }
}

.input-hidden {
  visibility: hidden;
}
</style>
