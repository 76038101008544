import { trainingCourseManager } from "@/services/training-course/training-course-manager";
import { triadManager } from "@/services/triad/triad-manager";
import { defineStore } from "pinia";
import { useMainStore } from "@/store/main/main";

export const useTrainingCourseStore = defineStore({
  id: "TrainingCourse",
  state: () => {
    return {
      trainingCourseList: {},
      trainingCourse: {},
      infos: {},
    };
  },

  actions: {
    findTrainingCourse(list, id) {
      if (list && typeof list === "object") {
        return Object.values(list).find((item) => item.id === id);
      }
    },

    fetchTrainingCourseList(payload) {
      if (useMainStore().isInternet) {
        return trainingCourseManager
          .loadTrainingCourseList(
            payload.archive,
            payload.search,
            payload.page,
            parseInt(window.localStorage.getItem("studea-oldTrainingCourse")),
          )
          .then((res) => {
            if (res.trainingCourses) {
              this.trainingCourseList = res.trainingCourses;
              if (
                Object.keys(res.trainingCourses).length &&
                !window.localStorage.getItem("studea-oldTrainingCourse")
              ) {
                this.setTrainingCourse(res.trainingCourses[0]);
              } else {
                this.toggleTrainingCourse(
                  parseInt(
                    window.localStorage.getItem("studea-oldTrainingCourse"),
                  ),
                );
              }
            }

            if (!payload.search && Object.keys(res.trainingCourses).length) {
              this.fetchTrainingCourseTriads({
                id: this.trainingCourse.id,
                toggle: true,
                archive: payload.archive ? payload.archive : 1,
              });
            }
          });
      }
    },

    fetchTrainingCourse(id) {
      return trainingCourseManager
        .loadTrainingCourse(id)
        .then((trainingCourse) => {
          this.trainingCourse = trainingCourse;
        });
    },

    fetchTrainingCourseTriads(payload) {
      if (payload.toggle) {
        this.toggleTrainingCourse(payload.id);
      }
      this.trainingCourse["loading"] = true;

      return triadManager
        .loadTriadList(payload.id, payload.archive)
        .then((list) => {
          this.setTrainingCourseTriads(list);
          this.trainingCourse["loading"] = false;
        });
    },

    setTrainingCourse(trainingCourse) {
      if (trainingCourse.id) {
        window.localStorage.setItem(
          "studea-oldTrainingCourse",
          trainingCourse.id,
        );
      }
      this.trainingCourse = trainingCourse;
    },

    toggleTrainingCourse(id) {
      if (this.trainingCourseList.length) {
        this.trainingCourse = this.findTrainingCourse(
          this.trainingCourseList,
          id,
        );
        if (!this.trainingCourse) {
          this.trainingCourse = this.trainingCourseList[0];
        }

        window.localStorage.setItem(
          "studea-oldTrainingCourse",
          this.trainingCourse.id,
        );
      }
    },

    setTrainingCourseTriads(list) {
      if (this.trainingCourse && this.trainingCourse.id) {
        const trainingCourse = this.findTrainingCourse(
          this.trainingCourseList,
          this.trainingCourse.id,
        );
        trainingCourse["crews"] = list;
      }
    },

    setTrainingCourseInfos(triad) {
      let infos = [];
      if (triad.trainingCourse && triad.trainingCourse.displayPresentation) {
        infos.push({
          label: triad.trainingCourse.blocNamePresentation,
          content: triad.trainingCourse.presentation,
          type: "presentation",
        });
      }
      if (triad.trainingCourse && triad.trainingCourse.displayContact) {
        infos.push({
          label: triad.trainingCourse.blocNameContact,
          content: triad.trainingCourse.contact,
          type: "contact",
        });
      }
      if (triad.trainingCourse && triad.trainingCourse.displayPartner) {
        infos.push({
          label: triad.trainingCourse.blocNamePartner,
          content: triad.trainingCourse.partner,
          type: "partner",
        });
      }
      if (triad.trainingCourse && triad.trainingCourse.displayCommunication) {
        infos.push({
          label: triad.trainingCourse.blocNameCommunication,
          content: triad.trainingCourse.communication,
          type: "communication",
        });
      }

      this.infos = infos;
    },

    addTrainingCourses(trainingCourses) {
      this.trainingCourseList = this.trainingCourseList.concat(trainingCourses);
    },

    init() {
      this.trainingCourseList = {};
      this.trainingCourse = {};
      this.infos = {};
    },
  },
});
