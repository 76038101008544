<template>
  <div class="row">
    <div class="col-lg-10 order-1 order-lg-0">
      <div class="widget">
        <div class="widget-heading flex-column">
          <h1 class="textStyle">
            <router-link
              :to="{ name: 'adminUsersList' }"
              class="btn-icon"
              v-tooltip="{ title: $t('back') }"
            >
              <FontAwesomeIcon icon="fa-light fa-arrow-left" />
            </router-link>
            {{ $t("admin.user.merge") }}
          </h1>
          <p class="fst-italic mt-1">
            <FontAwesomeIcon icon="fa-light fa-circle-info" />
            {{ duplicateUsers.length }}
            {{ $t("admin.user.duplicated_user_count") }}
          </p>
        </div>
        <div class="widget-content">
          <div v-if="duplicateUsers && duplicateUsers.length" class="list">
            <div class="mb-4 col-12 col-lg-3">
              <span class="d-block mb-2">{{
                $t("admin.user.search_user_by_name_or_mail")
              }}</span>
              <InputTypeComponent :model="keywordSearch" @input="search" />
            </div>
            <div class="list__heading">
              <div>{{ $t("admin.user.row") }}</div>
              <div>{{ $t("admin.user.reference_user") }}</div>
              <div>{{ $t("admin.user.duplicated_user") }}</div>
              <div>{{ $t("admin.user.options") }}</div>
            </div>
            <div class="list__content">
              <div
                class="list__row"
                v-for="(duplicateUser, index) in duplicateUsers"
                :key="duplicateUser.id"
              >
                <div class="list__item">{{ index + 1 }}</div>
                <div class="list__item">
                  <div class="mobile__row__title">
                    {{ $t("admin.user.reference_user") }}
                  </div>
                  <TriadDuplicatedUserDetailComponent
                    :user="duplicateUser.referenceUser"
                  />
                </div>
                <div>
                  <div class="mobile__row__title">
                    {{ $t("admin.user.duplicated_user") }}
                  </div>
                  <div class="list__item">
                    <TriadDuplicatedUserDetailComponent
                      v-for="user in duplicateUser.duplicateUsers"
                      :key="user.id"
                      :user="user"
                    />
                  </div>
                </div>
                <div class="list__item">
                  <div class="mobile__row__title">
                    {{ $t("admin.user.options") }}
                  </div>
                  <div>
                    <CheckboxInputComponent
                      :choices="sendBackLogin.choices"
                      :model="sendBackLogin.model"
                    />
                    <CheckboxInputComponent
                      :choices="notDuplicatedUser.choices"
                      :model="notDuplicatedUser.model"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>{{ $t("admin.user.no_double_user") }}</div>
        </div>
      </div>
    </div>
    <div class="col-lg-2 order-lg-1 order-0">
      <ActionsBlock :actions="actions" />
    </div>
  </div>
</template>

<script>
import ActionsBlock from "@/components/blocks/ActionsBlock.vue";
import { accessManager } from "@/services/security/access-manager";
import TriadDuplicatedUserDetailComponent from "@/components/triad/TriadDuplicatedUserDetailComponent.vue";
import CheckboxInputComponent from "@/components/form/CheckboxInputComponent.vue";
import InputTypeComponent from "@/components/form/InputTypeComponent.vue";

export default {
  name: "UserMergeView",
  components: {
    InputTypeComponent,
    CheckboxInputComponent,
    TriadDuplicatedUserDetailComponent,
    ActionsBlock,
  },
  data() {
    return {
      keywordSearch: {
        vars: {
          name: "keyword",
          value: "",
          id: "keyword",
          required: false,
          block_prefixes: ["filter", "text"],
          disabled: false,
          attr: {
            placeholder: this.$t("filter"),
          },
          row_attr: {
            class: "h-100",
          },
        },
      },
      duplicateUsers: [
        {
          id: 0,
          referenceUser: {
            id: 1,
            fullName: "Dupont Jean",
            mail: "j.dupont@gamil.com",
            usernmae: "jean.dupont",
            alreadyConnectedOne: true,
            createdAt: "2024-03-14 16:38:49",
            externalId: "ypareo_test_1212",
            birthDate: "2005-03-06",
          },
          duplicateUsers: [
            {
              id: 2,
              fullName: "Jean Dupont",
              mail: "j.dupont@gamil.com",
              usernmae: "jean.dupont",
              alreadyConnectedOne: false,
              createdAt: "2024-03-14 16:38:49",
              externalId: "ypareo_test_1212",
              birthDate: "2005-03-06",
            },
          ],
        },
        {
          id: 1,
          referenceUser: {
            id: 3,
            fullName: "Durand Pierre",
            mail: "p.durand@gamil.com",
            usernmae: "pierre.durand",
            alreadyConnectedOne: false,
            createdAt: "2024-03-14 16:38:49",
            externalId: "ypareo_test_1212",
            birthDate: "2005-03-06",
          },
          duplicateUsers: [
            {
              id: 4,
              fullName: "Pierre Durand",
              mail: "p.durand@gamil.com",
              usernmae: "pierre.durand",
              alreadyConnectedOne: true,
              createdAt: "2024-03-14 16:38:49",
              externalId: "ypareo_test_1212",
              birthDate: "2005-03-06",
            },
            {
              id: 5,
              fullName: "Pierre Durant",
              mail: "p.durand@gamil.com",
              usernmae: "pierre.durand",
              alreadyConnectedOne: true,
              createdAt: "2024-03-14 16:38:49",
              externalId: "ypareo_test_1212",
              birthDate: "2005-03-06",
            },
          ],
        },
        {
          id: 0,
          referenceUser: {
            id: 6,
            fullName: "Test Jean",
            mail: "j.test@gamil.com",
            usernmae: "jean.test",
            alreadyConnectedOne: true,
            createdAt: "2024-03-14 16:38:49",
            externalId: "ypareo_test_1212",
            birthDate: "2005-03-06",
          },
          duplicateUsers: [
            {
              id: 7,
              fullName: "Jean test",
              mail: "j.test@gamil.com",
              usernmae: "jean.test",
              alreadyConnectedOne: false,
              createdAt: "2024-03-14 16:38:49",
              externalId: "ypareo_test_1212",
              birthDate: "2005-03-06",
            },
          ],
        },
      ],
      actions: [
        {
          translation: "admin.user.merge",
          icon: "merge",
          type: "button",
          action: "mergeUsers",
          cfaStyle: true,
          access: accessManager.NONE,
        },
        {
          translation: "admin.user.export",
          icon: "download",
          type: "button",
          action: "export",
          cfaStyle: true,
          access: accessManager.NONE,
        },
      ],
      sendBackLogin: {
        model: {
          vars: {
            label: this.$t("admin.user.send_login_back_to_user"),
            name: "sendBackLogin",
            attr: [],
            disabled: false,
            id: "user_sendBackLogin",
            required: true,
          },
        },
        choices: [
          {
            label: this.$t("admin.user.send_login_back_to_user"),
            value: "1",
            data: true,
            attr: [],
          },
        ],
      },
      notDuplicatedUser: {
        model: {
          vars: {
            label: this.$t("admin.user.send_login_back_to_user"),
            name: "notDuplicatedUser",
            attr: [],
            disabled: false,
            id: "user_notDuplicatedUser",
            required: true,
          },
        },
        choices: [
          {
            label: this.$t("admin.user.reference_user_is_not_duplicate"),
            value: "1",
            data: true,
            attr: [],
          },
        ],
      },
    };
  },
  watch: {
    menu() {
      if (Object.keys(this.menu).length) {
        this.getCurrentMenuEntry();
        this.init();
      }
    },
  },
  methods: {
    search() {
      console.log("ok");
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  width: 100%;

  &__heading {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    font-size: 14px;
    color: $primary;
    padding-bottom: 8px;
    border-bottom: 1px solid #adadad;

    @include up-to-md {
      display: none;
    }

    & div {
      width: 100%;

      &:first-child {
        flex-basis: 15%;
      }

      &:last-child {
        flex-basis: 50%;
      }
    }
  }

  &__row {
    display: flex;
    border-bottom: 1px dotted #adadad;
    padding: 8px 0;

    @include up-to-md {
      flex-direction: column;
    }

    & div {
      width: 100%;

      &:first-child {
        flex-basis: 15%;
      }

      &:last-child {
        flex-basis: 50%;
      }
    }
  }

  &__item {
    padding-right: 8px;

    @include up-to-md {
      margin-bottom: 30px;
    }

    &:not(:last-child) {
      @include up-to-md {
        margin-bottom: 20px;
      }
    }
  }
}

.mobile__row__title {
  display: none;
  font-weight: 700;
  font-size: 14px;
  color: $primary;
  padding-bottom: 8px;
  @include up-to-md {
    display: block;
  }
}
</style>
