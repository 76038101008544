<template>
  <template v-if="tabs && Object.keys(tabs).length">
    <FormTabsBlockComponent
      :loading="loading"
      :tabs="tabs"
      :invalidTab="invalidTab"
      :hideCancel="true"
      :removeSubmit="true"
      :keepHideValue="true"
    />
    <div class="widget">
      <div class="d-flex justify-content-center">
        <SubmitTypeComponent :model="submitModel" @submitForm="submit()" />
        <div class="form-group mt-3 text-center">
          <button class="btn btn-danger" @click="cancel">
            {{ $t("cancel") }}
          </button>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import { notificationManager } from "@/services/utilities/notification-manager";
import FormTabsBlockComponent from "@/components/blocks/FormTabsBlockComponent.vue";
import { formManager } from "@/services/form/form-manager";
import { apiConnection } from "@/services/api-connection";
import { formValidator } from "@/services/form/form-validator";
import { adminManager } from "@/services/admin/admin-manager";
import SubmitTypeComponent from "@/components/form/SubmitTypeComponent.vue";

export default {
  name: "NewsAddEditView",
  data() {
    return {
      loading: false,
      tabs: [],
      models: [],
      params: {},
      invalidTab: null,
      submitModel: {
        vars: {
          block_prefixes: ["", "submit"],
          hideCancel: true,
          display: true,
          id: "submit",
        },
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loading = true;
      adminManager.form("news", this.$route.params.id).then((data) => {
        this.tabs = data.tabs;
        this.loading = false;
      });
    },

    cancel() {
      this.$router.push({
        name: "adminList",
      });
    },

    submit() {
      let response = [];
      this.loading = true;
      this.invalidTab = null;

      Object.values(this.tabs).forEach((tab) => {
        if (!tab.hidden) {
          response.push({
            form: tab.models ? formManager.processForm(tab.models, false) : [],
            code: tab.code,
          });
        }
      });
      const urlCall =
        this.$route.name === "adminNewsEdit"
          ? "/app/admin/news/edit/" + this.$route.params.id
          : "/app/admin/news/add";

      console.log(response);

      if (this.$route.name === "adminNewsAdd") {
        apiConnection.post(urlCall, response).then((data) => {
          this.checkFetchResponse(data);
          this.init();
          this.loading = false;
        });
      }

      if (this.$route.name === "adminNewsEdit") {
        apiConnection.put(urlCall, response).then((data) => {
          this.checkFetchResponse(data);
          this.init();
          this.loading = false;
        });
      }
    },

    checkFetchResponse(data) {
      if (data && !data.hasError) {
        this.$router.push({
          name: "adminModuleNewsList",
          query: { time: Date.now() },
        });
        notificationManager.showNotification(
          "success",
          this.$tc("global.edit.success", 1, {
            name: this.$t("admin.news.title"),
          }),
        );
      } else if (data.responses && data.responses[0].validationErrors) {
        notificationManager.showNotification("error", this.$t("signed_error"));
        Object.entries(this.tabs).forEach((tab) => {
          const errors = formValidator.processResponses(
            data.responses[0].validationErrors,
            tab[1].models,
          );
          if (errors.length) {
            this.invalidTab = this.id ? tab[1] : tab[0];
          }
        });
      }
    },

    validTab() {
      this.invalidTab = null;
    },
  },
  components: {
    SubmitTypeComponent,
    FormTabsBlockComponent,
  },
};
</script>

<style lang="scss" scoped>
.widget-content {
  padding: 20px;
}
</style>
