<template>
  <div :class="'form full-form auth-cover' + (!isWeb() ? ' mobile' : '')">
    <div class="form-container">
      <div class="form-form">
        <div class="form-form-wrap">
          <div class="form-container">
            <div class="form-content">
              <router-link :to="{ name: 'Login' }">
                <img
                  :src="logoCfa ? logoCfa : '/assets/images/logo_studea.png'"
                  alt="Logo Cfa"
                  class="logo"
              /></router-link>
              <router-view></router-view>
              <a
                class="about"
                href="javascript:void(0);"
                @click="open('presentation')"
                v-if="design && design.presentation"
                ><span>{{ $t("login_presentation") }}</span></a
              >
              <p class="terms-conditions">
                © 2024 Effet B
                <br />
                <span v-if="this.design && this.design.legalNotice">
                  <a href="javascript:void(0);" @click="open('legal_notice')">{{
                    $t("legal_notice")
                  }}</a
                  >,
                </span>
                <a href="javascript:void(0);" @click="open('personal_data')">{{
                  $t("personal_data")
                }}</a
                >,
                <span v-if="this.design && this.design.termOfService">
                  <a
                    href="javascript:void(0);"
                    @click="open('term_of_service')"
                    >{{ $t("terms_and_conditions") }}</a
                  >,</span
                >
                <a
                  v-if="this.design && this.design.helpContactEmail"
                  :href="'mailto:' + this.design.helpContactEmail"
                  >{{ $t("contact_us") }}</a
                >
              </p>
              <div class="studea-logo">
                <a href="https://www.studea.fr" target="_blank"
                  ><img alt="Studea" src="/assets/images/logo_studea.png"
                /></a>
              </div>
              <div class="text-center" v-if="appBuildVersion">
                v.{{ appBuildVersion }}
              </div>
              <div class="text-center" v-if="appWebVersion">
                b.{{ appWebVersion }}
              </div>
              <div
                v-if="design && design.homeAlert"
                class="homeAlert"
                v-html="design.homeAlert"
              ></div>
            </div>
          </div>
        </div>
        <LoginDocumentComponent />
      </div>
      <div class="form-image">
        <img
          :src="logoHomepage ? logoHomepage : '/assets/images/logo_illustr.jpg'"
          alt="Logo page d'accueil"
        />
      </div>
    </div>
  </div>
  <div
    id="login-modal"
    aria-hidden="true"
    aria-labelledby="login-modal"
    class="modal fade modal-xl"
    role="dialog"
    tabindex="-1"
    ref="modal"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="exampleModalLabel" class="modal-title">
            {{ modalTitle }}
          </h5>
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            data-dismiss="modal"
            type="button"
          ></button>
        </div>
        <div
          v-if="!personnalData"
          class="modal-body"
          v-html="modalContent"
        ></div>
        <div v-else class="modal-body">
          <h1>{{ $t("Personal data - Confidentiality") }}</h1>
          <p>
            {{
              $t(
                "Since May 25, 2018, the entry into force of the General Data Protection Regulation has been amended; regulations on personal data.",
              )
            }}
          </p>
          <p>
            {{
              $t(
                "We invite you to read the information notices below. The origin, purpose and processing carried out on the data collected by Studea depend, ... according to the profile of each user.",
              )
            }}
          </p>
          <p>
            {{
              $t(
                "We offer 3 notices here, depending on your user profile, we invite you to read the one that corresponds to your case",
              )
            }}:
          </p>
          <h2>
            {{
              $t(
                "You are a work-study student and you have been registered on the platform by your training organization",
              )
            }}:
          </h2>
          <a href="javascript:void(0);" @click="open('learner_privacy')"
            >> {{ $t("Personal data - Confidentiality (Alternate access)") }}</a
          >
          <h2>
            {{
              $t(
                "You are an employee of a company and you supervise a work-study program as a Tutor",
              )
            }}
          </h2>
          <a
            href="javascript:void(0);"
            @click="open('professional_tutor_privacy')"
            >>
            {{
              $t(
                "Personal data - Confidentiality (apprenticeship masters and company tutor)",
              )
            }}</a
          >
          <h2>
            {{
              $t(
                "You are an employee of a training organization, you administratively manage training courses or you supervise young people as an educational tutor",
              )
            }}
          </h2>
          <a href="javascript:void(0);" @click="open('pedagogic_tutor_privacy')"
            >>
            {{
              $t(
                "Personal data - Confidentiality (Employees of training organizations, educational tutor)",
              )
            }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/sass/authentication/auth.scss";
import { Capacitor } from "@capacitor/core";
import { loginManager } from "@/services/security/login-manager";
import { Modal } from "bootstrap";
import LoginDocumentComponent from "@/components/LoginDocumentComponent.vue";
import { mapState, mapActions } from "pinia";
import { usePlatformStore } from "@/store/platform/platform";
import { useUserStore } from "@/store/user/user";
import { useMainStore } from "@/store/main/main";
import { useTrainingCourseStore } from "@/store/training-course/training-course";

export default {
  name: "LayoutView",
  components: { LoginDocumentComponent },
  data() {
    return {
      login: "",
      loginError: "",
      password: "",
      passwordError: "",
      showPassword: false,
      stayConnected: false,
      modalTitle: "",
      modalContent: "",
      personnalData: false,
      modalOpenened: false,
    };
  },
  beforeMount() {
    this.setAppLoading(true);
    if (loginManager.isToken() && this.$route.name !== "loginSwitch") {
      loginManager.refreshConnection().then((success) => {
        if (success) {
          window.localStorage.removeItem("studea-oldTriad");
          window.localStorage.removeItem("studea-oldTrainingCourse");
          this.fetchUserInfos().then(() => {
            this.fetchTrainingCourseList({}).then(() => {
              if (
                this.user &&
                this.user.roles &&
                this.user.roles.includes("ROLE_STUDEA_ADMIN")
              ) {
                this.setAppLoading(false);
                window.localStorage.setItem(
                  "studea-returnPathName",
                  JSON.stringify({ name: "TrainingCourseList" }),
                );
                this.$router.push({ name: "adminHome" });
              } else if (Object.keys(this.trainingCourses).length > 1) {
                this.setAppLoading(false);
                this.$router.push({ name: "TrainingCourseList" });
              } else {
                this.$router.push({ name: "Dashboard" });
              }
            });
          });
        }
      });
    } else {
      if (Capacitor.getPlatform() === "web" || loginManager.getApiPath()) {
        this.fetchDesign({
          disableLoading: !this.$route.matched.find(
            (item) => item.name === "loginSwitch",
          ),
        });
      }
    }
  },
  mounted() {
    this.$refs.modal.addEventListener("hidden.bs.modal", this.hidden);
  },
  computed: {
    ...mapState(usePlatformStore, {
      design: (store) => store.design,
      dataAgreement: (store) => store.dataAgreement,
      logoCfa: (store) => store.logoCfa,
      logoHomepage: (store) => store.logoHomepage,
    }),
    ...mapState(useUserStore, {
      user: (store) => store.user,
    }),
    ...mapState(useMainStore, {
      isMobile: (store) => store.isMobile,
      appBuildVersion: (store) => store.appBuildVersion,
      appWebVersion: (store) => store.appWebVersion,
    }),
    ...mapState(useTrainingCourseStore, {
      trainingCourses: (store) => store.trainingCourseList,
    }),
  },
  methods: {
    isWeb() {
      return Capacitor.getPlatform() === "web";
    },

    open(type) {
      this.personnalData = false;
      switch (type) {
        case "presentation":
          this.modalTitle = this.$t("login_presentation");
          this.modalContent = this.design.presentation;
          break;
        case "legal_notice":
          this.modalTitle = this.$t("legal_notice");
          this.modalContent = this.dataAgreement.legalNotice;
          break;
        case "term_of_service":
          this.modalTitle = this.$t("terms_and_conditions");
          this.modalContent = this.dataAgreement.termOfService;
          break;
        case "personal_data":
          this.modalTitle = this.$t("personal_data");
          this.personnalData = true;
          break;
        case "learner_privacy":
          this.modalTitle = this.$t("learner_privacy");
          this.modalContent = this.dataAgreement.learnerPrivacy;
          break;
        case "professional_tutor_privacy":
          this.modalTitle = this.$t("professional_tutor_privacy");
          this.modalContent = this.dataAgreement.professionalTutorPrivacy;
          break;
        case "pedagogic_tutor_privacy":
          this.modalTitle = this.$t("pedagogic_tutor_privacy");
          this.modalContent = this.dataAgreement.pedagogicTutorPrivacy;
          break;
      }
      if (!this.modalOpenened) {
        const container = this.$refs.modal;
        const modal = new Modal(container);
        modal.show();
        this.modalOpenened = true;
      }
    },

    hidden() {
      this.modalOpenened = false;
    },

    ...mapActions(usePlatformStore, ["fetchDesign"]),
    ...mapActions(useMainStore, ["setAppLoading"]),
    ...mapActions(useTrainingCourseStore, ["fetchTrainingCourseList"]),
    ...mapActions(useUserStore, ["fetchUserInfos"]),
  },
};
</script>

<style lang="scss" scoped>
.form-image {
  img {
    height: 100%;
    object-fit: cover;
  }
}

.form-container {
  @include up-to-lg {
    align-items: flex-start;
  }

  @include up-to-md {
    padding: 20px;
  }
}

.form-content {
  @include up-to-lg {
    position: relative;
  }
}

.logo {
  position: relative;
  width: 60%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 25px;
  object-fit: contain;

  @include up-to-md {
    width: 50%;
    min-height: 168px;
  }
}

.studea-logo {
  text-align: center;
  margin-top: 25px;

  img {
    height: 45px;
  }
}

.mobile {
  min-height: 100vh;
  height: 1px;

  .form-form,
  .form-form-wrap {
    min-height: 100%;
    height: 1px;
    width: 100%;
  }

  h1 {
    height: auto;

    &.start {
      font-size: 16px;
      font-weight: normal;
    }
  }

  .form-content {
    height: 100%;
  }
}

.form-form {
  position: relative;
  overflow: hidden;
}

.about {
  cursor: pointer;
  display: block;
  text-align: center;
  width: 100%;
  margin-top: 25px;
  position: relative;
  font-size: 16px;
  font-weight: 700;
  transform: scale(1);
  transition: 100ms ease-in-out;
  animation: 500ms ease-in-out alternate grow-up-down;
  animation-delay: 300ms;

  span {
    z-index: 2;
    background: $m-color_1;
    position: relative;
    padding: 0 10px;
    color: var(--primary-color);
  }

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    height: 2px;
    background: var(--primary-color);
    z-index: 1;
  }

  &:hover {
    transform: scale(1.05);
  }
}

.homeAlert {
  background: $light-red;
  color: $red;
  text-align: center;
  padding: 20px;
  position: relative;
  width: 150%;
  left: -25%;
  margin-top: 20px;

  @include up-to-md {
    width: 100%;
    left: initial;
  }
}

#login-modal,
.modal-header,
.modal-body,
.modal-content {
  color: var(--primary-color) !important;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--primary-color) !important;
  }
}

.modal-body {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 15px 0 25px 0;
  }
}

@keyframes grow-up-down {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(1.1);
  }
  66% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
</style>
